<template>
  <div class="card">
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/info'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.info") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/weights'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.weight") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/vaccines'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.vaccination") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/diseases'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.diseases") }}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="'/animal/' + Number(animalId) + '/feeds'"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                {{ $t("menuPages.animalInfo.tabMenu.feed") }}
              </router-link>
            </li>
            <!--end::Nav item-->
          </ul>
        </div>
        <!--end::Followers toolbar-->

        <h3 class="card-title align-items-start flex-column pt-8">
          <span class="card-label fw-bolder fs-3">{{
            $t("menuPages.disease.pageName")
          }}</span>
        </h3>

        <!--begin::Row-->
        <div
            v-if="isLoading"
            style="
              height: 500px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div class="text-center px-5 pb-7">
              <img
                alt="Logo"
                class="max-h-35px"
                src="/media/loading_icon.gif"
                style="width: 40px"
              />
            </div>
          </div>
        <div
          class="row g-6 mb-6 g-xl-9 mb-xl-9"
          v-else-if="animalDiseaseList.length > 0"
        >
          <!--begin-->
          <div
            class="col-md-6 col-xxl-3"
            v-for="(animalDisease, index) in animalDiseaseList"
            :key="animalDisease.disease.id"
          >
            <!--begin::Card-->
            <div class="card border">
              <!--begin::Card body-->
              <div class="card-body d-flex flex-center flex-column p-7 h-150px">
                <!--begin::Name-->
                <a
                  href="#"
                  class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0"
                  >{{ animalDisease.disease.diseaseName }}</a
                >
                <!--end::Name-->

                <!--begin::Position-->
                <div class="fw-bold text-gray-400 mb-3 mt-1">
                  {{
                    new Date(
                      animalDisease.disease.startDate
                    ).toLocaleDateString("tr-TR")
                  }}
                  <span v-if="animalDisease.disease.endDate">
                    -
                    {{
                      new Date(
                        animalDisease.disease.endDate
                      ).toLocaleDateString("tr-TR")
                    }}
                  </span>
                </div>
                <!--end::Position-->

                <!--begin::Follow-->
                <span
                  id="diseaseStatus"
                  class="badge fs-8 fw-bolder"
                  :class="{
                    'badge-light-success': animalDisease.disease.endDate,
                    'badge-light-warning': !animalDisease.disease.endDate,
                  }"
                  style="margin-bottom: 8px"
                >
                  {{
                    animalDisease.disease.endDate ? "Atlattı" : "Devam ediyor"
                  }}
                </span>
                <button
                  v-if="!animalDisease.disease.endDate"
                  class="btn btn-sm btn-primary"
                  style="height: 20px; padding-top: 2px"
                  @click="treatAnimal(animalDisease.disease.id)"
                >
                  Tedavi Et
                </button>
                <!--end::Follow-->
              </div>
              <!--begin::Card body-->
            </div>
            <!--begin::Card-->
          </div>
          <!--end::Followers-->
        </div>
        <div v-else class="card-px text-center">
          <div class="text-center px-5 pb-7 pt-5">
            <img
              src="/media/animal/animal.jpg"
              class="symbol symbol-100px symbol-lg-160px mw-100 mh-300px"
            />
          </div>
          <!--begin::Description-->
          <p class="text-gray-400 fs-5 fw-bold">
            <span>{{ $t("menuPages.disease.zeroCount") }}</span>
          </p>
          <!--end::Description-->
        </div>
      </div>
    </div>
  </div>
  <Vaccinate></Vaccinate>
  <AddWeight></AddWeight>
  <AddDisease></AddDisease>
  <Feed></Feed>
  <AnimalTransfer></AnimalTransfer>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import Feed from "@/presentation/components/Feed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useRoute } from "vue-router";
import { AnimalDiseaseListModel } from "@/domain/animal/animalDisease/animalDiseaseList/model/AnimalDiseaseListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { AnimalDiseaseUpdateModel } from "@/domain/animal/animalDisease/animalDiseaseUpdate/model/AnimalDiseaseUpdateModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: { Vaccinate, AddWeight, AddDisease, Feed, AnimalTransfer },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const swalNotification = new SwalNotification();
    const animalController = store.state.ControllersModule.animalController;
    const animalDiseaseList = ref<AnimalDiseaseListModel[]>([]);

    const animalId = route.params["id"];

    const isLoading = ref<boolean>(false);

    const currentPage = ref(1);
    const pageSize = ref(20);
    const totalItems = ref(0);
    const pageCount = ref(1);

    watch(
      () => store.getters.newDisease,
      () => {
        if (store.getters.newDisease) getAnimalDiseasesList(1);
      }
    );

    const getAnimalDiseasesList = (page) => {
      isLoading.value = true;

      animalDiseaseList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalDiseaseListModel: AnimalDiseaseListModel = {
        disease: {
          animalId: Number(animalId),
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalController
        .animalDiseaseList(animalDiseaseListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalDisease) => {
              totalItems.value = animalDisease.pagination.total;
              currentPage.value = animalDisease.pagination.page;
              pageCount.value = animalDisease.pagination.pageCount;
              animalDiseaseList.value.push(animalDisease);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const treatAnimal = (id) => {
      const animalDiseaseUpdateModel: AnimalDiseaseUpdateModel = {
        id: id,
        endDate: new Date(),
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_TREAT_ANIMAL),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          animalController
            .updateAnimalDisease(animalDiseaseUpdateModel)
            .then((response) => {
              if (response.isSuccess) {
                store.dispatch("newDisease", store.getters.newDisease + 1 );
                Swal.fire({
                  title: t(SWAL_MESSAGES.SUCCESS),
                  text: t(SWAL_MESSAGES.SUCCESS_UPDATE_ANIMAL_DISEASE),
                  icon: "success",
                }).then(() => {
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });

    }
    
    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      getAnimalDiseasesList(1);
    });

    return {
      PAGE_TITLE_PATH,
      animalId,
      animalDiseaseList,
      treatAnimal,
      isLoading,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
