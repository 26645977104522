<template>
  <!--begin::Modal - New Target-->
  <div
    class="modal fade"
    id="add_disease_modal"
    ref="newTargetModalRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-800px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body px-10 px-lg-15 pt-0 pb-15">
          <!--begin:Form-->
          <Form
            id="vaccinate_modal_form"
            novalidate="novalidate"
            @submit="submit"
            :validation-schema="form"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">{{ $t("menuPages.addDisease.pageName") }}</h1>
              <!--end::Title-->

              <!--begin::Description-->
              <div class="text-gray-400 fw-bold fs-5">
                {{ $t("menuPages.addDisease.pageDescription") }}
              </div>
              <!--end::Description-->
            </div>
            <!--end::Heading-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-12 fv-row">
                <label class="required fs-6 fw-bold mb-2">{{
                  $t("menuPages.addDisease.disease")
                }}</label>

                <Field
                  name="disease"
                  data-control="select1"
                  data-hide-search="true"
                  data-placeholder='{{ $t("menuPages.addDisease.selectDisease") }}'
                  class="form-select form-select-solid"
                  as="select"
                  v-model="animalDiseaseAddModel.disease"
                >
                  <option value="0" selected>
                    {{ $t("menuPages.addDisease.selectDisease") }}
                  </option>
                  <option
                    :label="disease.disease.name"
                    :value="disease.disease.id"
                    v-for="(disease, index) in diseaseList"
                    :key="disease.disease.id"
                  >
                    {{ disease.disease.name }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="disease" />
                  </div>
                </div>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div :class="showEndDate ? 'col-md-6' : 'col-md-12'">
                <label class="required fs-6 fw-bold mb-2">{{
                  $t("menuPages.addDisease.startDate")
                }}</label>

                <!--begin::Input-->
                <div class="position-relative align-items-center">
                  <!--begin::Datepicker-->
                  <Field
                    v-model="animalDiseaseAddModel.startDate"
                    type="date"
                    name="startDate"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('menuPages.addDisease.enterStartDate')"
                    :max="new Date().toISOString().split('T')[0]"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="startDate" />
                    </div>
                  </div>
                  <!--end::Datepicker-->
                </div>
                <!--end::Input-->
              </div>
              <!--end::Col-->

              <!--begin::Col-->
              <div class="col-md-6 fv-row" v-if="showEndDate">
                <label class="fs-6 fw-bold mb-2">{{
                  $t("menuPages.addDisease.endDate")
                }}</label>

                <!--begin::Input-->
                <div class="position-relative align-items-center">
                  <!--begin::Datepicker-->
                  <Field
                    v-model="animalDiseaseAddModel.endDate"
                    type="date"
                    name="endDate"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('menuPages.addDisease.enterEndDate')"
                    :max="new Date().toISOString().split('T')[0]"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="endDate" />
                    </div>
                  </div>
                  <!--end::Datepicker-->
                </div>
                <!--end::Input-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="row g-9 mb-8">
              <!--begin::Col-->
              <div class="col-md-6 fv-row">
                <!--begin::Switch-->
                <label
                  class="
                    form-check form-switch form-check-custom form-check-solid
                  "
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                    v-model="showEndDate"
                  />
                  <span class="form-check-label fw-bold text-gray-400">
                    {{ $t("menuPages.addDisease.end") }}
                  </span>
                </label>
                <!--end::Switch-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Actions-->
            <div class="text-center">
              <!--begin::Button-->
              <button
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary"
                type="submit"
              >
                <span v-if="!loading" class="indicator-label">
                  {{ $t("common.button.save") }}
                  <span class="svg-icon svg-icon-3 ms-2 me-0">
                    <inline-svg src="icons/duotune/arrows/arr064.svg" />
                  </span>
                </span>
                <span v-if="loading" class="indicator-progress">
                  {{ $t("common.button.loader") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Button-->
            </div>
            <!--end::Actions-->
          </Form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Target-->
</template>

<style lang="scss">
.el-select {
  width: 100%;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/presentation/helper/Dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import SwalNotification from "../plugins/SwalNotification";
import { CUSTOM_ERROR_MESSAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { DiseaseListModel } from "@/domain/disease/diseaseList/model/DiseaseListModel";
import { AnimalDiseaseAddModel } from "@/domain/animal/animalDisease/animalDiseaseAdd/model/AnimalDiseaseAddModel";

export default defineComponent({
  name: "new-target-modal",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const showEndDate = ref<boolean>(false);
    const diseaseController = store.state.ControllersModule.diseaseController;

    const diseaseList = ref<DiseaseListModel[]>([]);

    const swalNotification = new SwalNotification();

    const animalDiseaseAddModel = ref<AnimalDiseaseAddModel>({
      disease: 0,
    });

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const vaccinationController =
      store.state.ControllersModule.vaccinationController;
    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }
    if (!vaccinationController) {
      throw new Error("VaccinationController is not available in Vuex store.");
    }

// Create form validation object
const form = Yup.object().shape({
  disease: Yup.string()
    .test(
      "isRequired",
      t(CUSTOM_ERROR_MESSAGES.required),
      function (value) {
        return value !== "0" && value !== null;
      }
    )
    .label("Disease"),
  startDate: Yup.string()
    .required(t(CUSTOM_ERROR_MESSAGES.required))
    .label("StartDate"),
});

    const getDiseases = () => {
      diseaseList.value = [];

      const diseaseListModel: DiseaseListModel = {
        disease: {
          id: 0,
        },
        page: 1,
        pageSize: pageSize.value,
      };

      diseaseController
        .diseaseList(diseaseListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((disease) => {
              totalItems.value = disease.pagination.total;
              currentPage.value = disease.pagination.page;
              pageCount.value = disease.pagination.pageCount;
              diseaseList.value.push(disease);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const submit = () => {
      loading.value = true;
      animalDiseaseAddModel.value.animal = Number(
        store.getters.selectedAnimalId
      );

      if (!showEndDate.value) {
        animalDiseaseAddModel.value.endDate = undefined;
      }

      animalController
        .addAnimalDisease(animalDiseaseAddModel.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            store.dispatch("newDisease", store.getters.newDisease + 1 );
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_ADD_ANIMAL_DISEASE),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      getDiseases();
    });

    return {
      animalDiseaseAddModel,
      submit,
      loading,
      formRef,
      newTargetModalRef,
      diseaseList,
      showEndDate,
      form,
    };
  },
});
</script>
