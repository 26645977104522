
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/presentation/helper/Dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import SwalNotification from "../plugins/SwalNotification";
import { CUSTOM_ERROR_MESSAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { DiseaseListModel } from "@/domain/disease/diseaseList/model/DiseaseListModel";
import { AnimalDiseaseAddModel } from "@/domain/animal/animalDisease/animalDiseaseAdd/model/AnimalDiseaseAddModel";

export default defineComponent({
  name: "new-target-modal",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const showEndDate = ref<boolean>(false);
    const diseaseController = store.state.ControllersModule.diseaseController;

    const diseaseList = ref<DiseaseListModel[]>([]);

    const swalNotification = new SwalNotification();

    const animalDiseaseAddModel = ref<AnimalDiseaseAddModel>({
      disease: 0,
    });

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const vaccinationController =
      store.state.ControllersModule.vaccinationController;
    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }
    if (!vaccinationController) {
      throw new Error("VaccinationController is not available in Vuex store.");
    }

// Create form validation object
const form = Yup.object().shape({
  disease: Yup.string()
    .test(
      "isRequired",
      t(CUSTOM_ERROR_MESSAGES.required),
      function (value) {
        return value !== "0" && value !== null;
      }
    )
    .label("Disease"),
  startDate: Yup.string()
    .required(t(CUSTOM_ERROR_MESSAGES.required))
    .label("StartDate"),
});

    const getDiseases = () => {
      diseaseList.value = [];

      const diseaseListModel: DiseaseListModel = {
        disease: {
          id: 0,
        },
        page: 1,
        pageSize: pageSize.value,
      };

      diseaseController
        .diseaseList(diseaseListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((disease) => {
              totalItems.value = disease.pagination.total;
              currentPage.value = disease.pagination.page;
              pageCount.value = disease.pagination.pageCount;
              diseaseList.value.push(disease);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const submit = () => {
      loading.value = true;
      animalDiseaseAddModel.value.animal = Number(
        store.getters.selectedAnimalId
      );

      if (!showEndDate.value) {
        animalDiseaseAddModel.value.endDate = undefined;
      }

      animalController
        .addAnimalDisease(animalDiseaseAddModel.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            store.dispatch("newDisease", store.getters.newDisease + 1 );
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_ADD_ANIMAL_DISEASE),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      getDiseases();
    });

    return {
      animalDiseaseAddModel,
      submit,
      loading,
      formRef,
      newTargetModalRef,
      diseaseList,
      showEndDate,
      form,
    };
  },
});
